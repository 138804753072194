// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.container-budget-1 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.container-budget-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-budget-3 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.container-budget-4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.container-budget-5 {
  width: 120px;
  height: 37px;
  background: green;
  border: 1px solid green;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.container-budget-5:hover {
  background-color: #ffffff;
  color: green;
}

.container-budget-6 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container-budget-7 {
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
  z-index: 9999 !important;
}

.container-budget-8 {
  display: flex;
  flex-direction: row;
  position: relative;
}

.container-budget-9 {
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 8px;
  margin-right: 15px;
  cursor: pointer;
  z-index: 9999 !important;
}

.container-budget-10 {
  width: 120px;
  height: 37px;
  background: #3699ff;
  border: 1px solid #3699ff;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.container-budget-10:hover {
  background-color: #ffffff;
  color: #3699ff;
}

.container-budget-11 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.container-budget-12 {
  width: 120px;
  height: 37px;
  background: #ffe2e5;
  border: 1px solid #ffe2e5;
  border-radius: 10px;
  color: #f64e60;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.container-budget-12:hover {
  background-color: #f64e60;
  color: #ffffff;
}

.container-budget-13 {
  width: 120px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #6c757d;
  border-radius: 10px;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.container-budget-13:hover {
  background-color: #6c757d;
  color: #ffffff;
}

.container-budget-14 {
  width: 40px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 360px;
  border: 2px solid #18c5bd;
}

.container-budget-15 {
  width: 38px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 360px;
  border: 2px solid #18c5bd;
}

.container-budget-16 {
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 8px;
  margin-right: 15px;
  cursor: pointer;
  z-index: 9999 !important;
}

.text-budget-1 {
  font-size: 18px;
  margin-top: 10px;
}
